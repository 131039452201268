import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["recordButton", "audio", "fileInput"];

  static values = {
    mediaId: Number,
    mediaType: String,
    user: Number
  };

  connect() {
    this.stream = null;
    this.mediaRecorder = null;
    this.chunks = [];
    this.audioBlob = null;
    this.isRecording = false;

    this.recordButtonTarget.disabled = false;
    // this.submitButtonTarget.disabled = true;
  }

  async toggleRecording() {
    if (this.isRecording) {
      this.stopRecording();
    } else {
      await this.startRecording();
    }
  }

  async startRecording() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Your browser does not support audio recording.");
      return;
    }

    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(this.stream);

      this.mediaRecorder.ondataavailable = (event) => {
        this.chunks.push(event.data);
      };

      this.mediaRecorder.onstop = () => {
        this.audioBlob = new Blob(this.chunks, { type: "audio/webm" });
        this.audioTarget.src = URL.createObjectURL(this.audioBlob);
        this.chunks = [];
        // this.submitButtonTarget.disabled = false;

        // Convert Blob to File and set it in the hidden input
        const file = new File([this.audioBlob], "audio_comment.webm", { type: "audio/webm" });
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        this.fileInputTarget.files = dataTransfer.files;

        console.log(this.fileInputTarget.files);
      };

      this.mediaRecorder.start();
      this.isRecording = true;
      this.updateRecordButtonText();
    } catch (error) {
      console.error("Error accessing the microphone:", error);
      alert("Could not access your microphone. Please check your settings.");
    }
  }

  stopRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
      this.mediaRecorder.stop();
      this.stream.getTracks().forEach((track) => track.stop());
      this.isRecording = false;
      this.audioTarget.style.display = "block";
      this.updateRecordButtonText();
    }
  }

  updateRecordButtonText() {
    this.recordButtonTarget.querySelector('span').textContent = this.isRecording ? "Stop Recording" : "Start Recording";
    this.recordButtonTarget.className = this.isRecording ? "button record-button stop" : "button record-button record";
  }
}
